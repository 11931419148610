import { CONSTANTS } from '../app/shared/constantes/constants-model';

export const environment = {
    production: false,
    appVersion: '1.0.0',
    USERDATA_KEY: 'authf649fc9a5f55',
    apiUrl: `${window.location.protocol}//${CONSTANTS.API_BASE_URL}`
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
